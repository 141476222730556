import firebase_instant from '../services/firebase'
import firebase from 'firebase'

export const addUserComment = async (userPhoneNumber, comment) => {
    try {
        if(!firebase_instant) return

        const db  = firebase_instant().firestore();
        const ref = db.collection("users");
        
        const user_doc = await ref.where('phone','==', userPhoneNumber).get()
    
        if (comment) {
            if (user_doc.docs[0] && (!user_doc.docs[0].data().wishes || (Array.isArray(user_doc.docs[0].data().wishes) && user_doc.docs[0].data().wishes.length < 100))) {
                await ref.doc(user_doc.docs[0].id).update(
                    {
                        wishes: firebase.firestore.FieldValue.arrayUnion(comment)
                    }
                )
            }   
        }
        
        return true;
    } catch(e) {
        console.log(e);
        return false;
    }   
}