import firebase from '../services/firebase'

export const getUserByPhoneNumber = async (userPhoneNumber) => {
    let user = {};
    try {
        if(!firebase) return

        const db  = firebase().firestore();
        const ref = db.collection("users");
        
        const docs = await ref.where('phone','==', userPhoneNumber).get()
    
        docs.forEach(doc => {
            user = {...{id: doc["id"]}, ...doc.data()}
        });
        
    } catch(e) {
        console.log(e);
    } finally {
        return user
    }
}

export const getUserById = async (id) => {
    let user = {};
    try {
        if(!firebase) return

        const db  = firebase().firestore();
        const ref = db.collection("users").doc(id);
        
        const docs = await ref.get()
    
        user = {...{id: docs["id"]}, ...docs.data()}
        
    } catch(e) {
        console.log(e);
    } finally {
        return user
    }
}