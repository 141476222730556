import React, {useRef, useContext} from 'react';
import uploadPhoto from '../../api/uploadPhotos'
import authContext from '../../context/authContext'
import {Typography, Button, makeStyles } from '@material-ui/core';

import NavButton from '../Common/NavButton';

const useStyle = makeStyles(()=>({
    root: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    }
}))

function ActionButtons({actions, isTopMenu}) {
    const classes = useStyle();
    const {setreloadPhotos} = actions
    const clickHandlers = {...actions["clickHandlers"]}
    const uploadRef = useRef(null);
    const [user] = useContext(authContext);

    /**
     * 
     * @param {string} clickHandler -> contains: actions keys ->  setclickWeddingPhotos, setclickUserPhotos, setclickWish
     */
    const handleClick = (clickHandler) => {
        if(clickHandlers[clickHandler]) {
            Object.keys(clickHandlers).forEach((key) => {
                if(key === clickHandler) {
                    clickHandlers[key](true);
                } else {
                    clickHandlers[key](false);
                } 
            })
        }
    }

    const handleUploadClick = () => {
        if (uploadRef) {
            uploadRef.current.click();
        }
    };

    // TODO should clear input files
    const handleUploadPhoto = async (event) => {

        const uploadedFile = event.target.files[0];

        await uploadPhoto(uploadedFile, user.id, ()=>setreloadPhotos(current => !current))

    }

    return (
        <div className={(isTopMenu ? classes.root : '')}>
            <NavButton isTopMenu={isTopMenu} onClickHandler={()=> handleClick("setclickWeddingPhotos")}>Wedding Photos</NavButton>
            <NavButton isTopMenu={isTopMenu} onClickHandler={()=> handleClick("setclickUserPhotos")}>Your Photos</NavButton>
            <NavButton isTopMenu={isTopMenu} onClickHandler={()=> handleClick("setclickWish")}>Write Your Wish</NavButton>
            <NavButton isTopMenu={isTopMenu} onClickHandler={handleUploadClick}>Upload Photos</NavButton>
            <input type="file" ref={uploadRef} onChange={handleUploadPhoto} accept=".png, .jpg, .jpeg" hidden />
        </div>
    );
}

export default ActionButtons;