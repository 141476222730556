import React, {useContext, useState, useEffect} from 'react';

import getPhotos from '../api/getPhotos'

import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import Layout from '../components/Common/Layout'

import authContext from '../context/authContext'

import ActionButtons from '../components/Profile/ActionButtons'
import Photos        from '../components/Profile/Photos'
import Wish          from '../components/Profile/Wish'
import IbanInfo     from '../components/Profile/IbanInfo';

const useStyle = makeStyles(() => ({
    titleProfile: {
        margin: "0 4vw",
    },
    titleText: {
        padding: "2vw 0",
        borderBottom: "1px solid"
    },
    actionButton: {
        display: 'flex',
        justifyContent: "center",
        marginTop: "100px",
        width: "30%"
    },
    actionButton_mobily: {
        display: 'flex',
        justifyContent: "center",
        marginTop: "20px",
        width: "100%"
    },
    photos: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center'
    },
    wish: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column',
        flexWrap: true,
        padding: '8px',
    }
}))

function Profile(props) {
    const modileViewPort = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyle()
    const [user] = useContext(authContext);
    const [clickWeddingPhotos, setclickWeddingPhotos] = useState(true);
    const [clickUserPhotos, setclickUserPhotos] = useState(false);
    const [clickWish, setclickWish] = useState(false);
    const [reloadPhotos, setreloadPhotos] = useState(false);
    const [userPhotos, setuserPhotos] = useState([])
    const [weddingPhotos, setweddingPhotos] = useState([])
    const {REACT_APP_ADMIN_ID} = process.env

    useEffect(() => {
        const weddingPhoto = async () => {
            const photos = await getPhotos(REACT_APP_ADMIN_ID)
            setweddingPhotos(photos);
        }
        weddingPhoto();
    }, [])

    useEffect(() => {
        const userPhoto = async () => {
            const photos = await getPhotos(user.id)
            setuserPhotos(photos);
        }

        if (clickUserPhotos && user.phone) {
            userPhoto();    
        }

    }, [clickUserPhotos, reloadPhotos])

    return (
        <Layout>
            <Grid item className={classes.titleProfile}>
                <Typography className={classes.titleText}>Hi, {user.name}</Typography>
            </Grid>
            <Grid item container>
                <Grid item container className={(modileViewPort ? classes.actionButton_mobily : classes.actionButton)}>
                    <ActionButtons isTopMenu={modileViewPort} actions={{clickHandlers: {setclickWeddingPhotos, setclickUserPhotos, setclickWish}, setreloadPhotos}}/>
                </Grid>
                {!clickWish ? 
                (<Grid item className={classes.photos}> 
                    <Photos isTopMenu={modileViewPort} data={clickWeddingPhotos ? weddingPhotos : clickUserPhotos ? userPhotos : weddingPhotos} actions={{setreloadPhotos}} hasDeleteOption={!clickWeddingPhotos && clickUserPhotos}/>
                </Grid>) : 
                (<Grid item className={classes.wish}>
                    <Wish style={{marginTop: '20px', width: (modileViewPort ? "320px" : "640px")}} />
                    <IbanInfo style={{marginTop: '45px'}}/>
                </Grid>)
                }
            </Grid>
        </Layout>
    );
}

export default Profile;