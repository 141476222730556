import React, {useState, useEffect, useContext} from 'react';
import {Typography, TextField, Button } from '@material-ui/core';
import authContext from '../../context/authContext'

import {addUserComment} from '../../api/addUserComment'

function Wish(props) {
    const [inputText, setinputText] = useState("")
    const [submitClick, setsubmitClick] = useState(false)
    const [user] = useContext(authContext)

    useEffect(() => {
        const handleSubmitClick = async () => {
            if(inputText) {
                console.log(inputText);
                await addUserComment(user.phone, inputText);
            }
            setinputText('');
        }       
        handleSubmitClick();
    }, [submitClick])

    return (
        <div style={props["style"]}>
            <Typography>Make Your Wish</Typography>
            <TextField
            id="wish text field"
            multiline
            rows={4}
            fullWidth
            defaultValue="Write your wish"
            variant="outlined"
            onChange={(event)=>setinputText(event.target.value)}
            />
            <Button onClick={()=>setsubmitClick(!submitClick)} style={{textTransform:"none", marginTop:"10px"}}>Add Your Wish</Button>

        </div>
    );
}

export default Wish;