import { Typography } from '@material-ui/core';
import React from 'react';

function IbanInfo(props) {
    const { typo_variant } = props; 
    return (
        <div style={props.style}>
            <div>
                <Typography variant={typo_variant} >ΙΒΑΝ Πειραιώς: </Typography>
                <Typography variant={typo_variant || "subtitle1"}>GR3701716390006639137977659</Typography>
            </div>
            <div>
                <Typography variant={typo_variant}>ΙΒΑΝ Eurobank: </Typography>
                <Typography variant={typo_variant || "subtitle1"}>GR3502600060000430201622145</Typography>
            </div>
        </div>
    );
}

export default IbanInfo;