import React from 'react';
import { Typography, Link, makeStyles } from '@material-ui/core';
//import { Link } from 'react-router-dom';

const useStyle = makeStyles((theme) => ({
    title: {
        ...theme.typography.title
    }
}))


function NavLink(props) {
    const {href, children, ...rest} = props
    const classes = useStyle();

    return (
        <Link href={href} style={{textDecoration: "none", color: 'black'}} {...rest}>
            <Typography className={classes.title}>{children}</Typography>
        </Link>
    );
}

export default NavLink;