import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Maps from '../Common/Maps'

const useStyle = makeStyles((theme)=>({
    root: {
        padding: '5%'
    },
    title_fonts: {
        ...theme.typography.title
    }
}))

function Section(props) {

    const {sectionTitle, sectionDesc, isRawReverse, img, map } = props;
    const classes = useStyle();

    return (
        <Grid id={sectionTitle} container direction='column' justify="center" className={classes.root}>
            <Grid item container justify="center" style={{marginBottom:"40px"}}>
                <Typography className={classes.title_fonts}>{sectionTitle}</Typography>
            </Grid>
            <Grid item container direction={isRawReverse ? 'row-reverse' : 'row'}>
                <Grid item container xs={12} sm={5} justify="center" alignItems="center">
                    <img {...img} />
                </Grid>
                <Grid item container justify='space-around' xs={12} sm={7}>
                    <Typography align="left" variant="caption" style={{width: '90%'}}>{sectionDesc}</Typography>
                    <div style={{width: '90%', marginTop: '15px'}} onDoubleClick={() => window.open(`https://goo.gl/maps/${map.redirectTo}`, '_blank')}>
                        <Maps extra_data={{lat: map.lat, lng: map.lng}} style={{position:"relative"}}/>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Section;