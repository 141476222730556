import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import IbanInfo from "../Profile/IbanInfo"

function Footer(props) {
    return (
        <Grid container justify="space-around" alignItem="center" style={{backgroundColor:"#F8F7F7", padding: "3rem"}}>
            <Typography variant="caption" style={{marginBottom: "10px"}}> Fannie ❤️ Giannis </Typography>
            <IbanInfo typo_variant="caption"/>
        </Grid>            
    );
}

export default Footer;