import {createMuiTheme} from '@material-ui/core'

export const theme = createMuiTheme({
    typography: {
        fontFamily: '"Century Gothic", Aka-AcidGR-Creepy, sans-serif',
        fontSize: 20,
        title: {
            fontFamily: 'Cute, Madina, sans-serif',
            fontSize: 38,
        }
    },
})