import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import Image from '../Common/Image'

const useStyle = makeStyles(() => ({
    sideMenu: {
        width: "500px", 
        height: "100vh", 
        overflowY: 'scroll', 
        flexGrow: 1
    },
    topMenu: {
        display: 'flex',
        minHeight: "100vh",
        flexDirection: "column", 
        alignItems: "center"
    }
}))

function Photos({data, isTopMenu, hasDeleteOption, actions}) {
    const classes = useStyle();
    return (
        <Box className={isTopMenu ? classes.topMenu : classes.sideMenu}>
                {data.map((item) => (
                    <div key={item.title} style={{padding: "8px", width: "320px", display: 'inline-block'}}>
                        <Image src={item.img} title={item.title} hasDeleteOption={hasDeleteOption} actions={actions}/>
                    </div>
                ))}
        </Box>
    );
}

export default Photos;


