import React, {useContext} from 'react';
import authContext from '../context/authContext'
import {
    Route,
    Redirect,
  } from "react-router-dom";

function ProtectedRoute({ children, ...rest }) {
    const [user] = useContext(authContext)
    return (
        <Route
            {...rest}
            render={({ location }) =>
            (user && user.isAuthenticated) ? (
                children
                ) : (
                <Redirect
                    to={{
                    pathname: "/",
                    state: { from: location }
                    }}
                />
                )
            }
        />
    );
}

export default ProtectedRoute;