import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core'

import NavLink from '../Common/NavLink'
import Login from '../Common/Login'

const useStyles = makeStyles(()=> ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    pageNav: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexGrow: '1',
    },
    logIn: {
        display: 'flex', 
        justifyContent: 'center',
    },
    nav_img: {
        marginTop: '8px', width: "25%"
    }
}))

function TopNavBar(props) {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.pageNav}>
                <NavLink href="/#Ceremony">Ceremony</NavLink>
                <NavLink href="/#Our Story">Our Story</NavLink>
                <NavLink href="/#Party">Party</NavLink>                
            </div>
            <div className={classes.logIn}>
                <Login />                            
            </div>
        </Paper>
    );
}

export default TopNavBar;