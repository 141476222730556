import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Modal, Backdrop, Fade, Typography, TextField, Button, useMediaQuery } from '@material-ui/core';
import authContext from '../../context/authContext'
import { Redirect, Link } from 'react-router-dom';

const useStyle = makeStyles((theme)=>({
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: 'white',
        borderWidth: "0px",
        padding: "40px 50px"
    },
    logIn: {
        display: "flex",
    },
    nav_fonts: {
        ...theme.typography.title
    }
}))

function Login() {
    const classes = useStyle();
    const [open, setopen] = useState(false);
    const [textInput, settextInput] = useState("")
    const [submitNumber, setsubmitNumber] = useState("")
    const [logoutClicked, setlogoutClicked] = useState(false);
    const [user, loginByPhone, logout] = useContext(authContext);
    const modileViewPort = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {
        if(submitNumber) {
            loginByPhone(submitNumber)
        }
    }, [submitNumber])

    useEffect(() => {
        if(logoutClicked) {
            logout()
        }
    }, [logoutClicked])

    return (
        <>
            {
            (user && user.isAuthenticated) ? 
                (<div style={{"display": (modileViewPort ? "" : "flex")}}>
                    <Link to='/profile' style={{textDecoration: "none", color: 'black', marginRight: "40px"}}><Typography className={classes.nav_fonts}>Upload Photos</Typography></Link>
                    <Typography className={classes.nav_fonts} onClick={()=> setlogoutClicked(true)}>LogOut</Typography>
                </div>) :
                <Typography className={classes.nav_fonts} onClick={()=> setopen(true)}>LogIn</Typography>
            }
            <Modal 
            className={classes.modal}
            open={open}
            onClose={()=>setopen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
            >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Typography style={{marginBottom: "20px"}}>Enter Phone Number</Typography>
                    {(submitNumber && user.hasOwnProperty('isAuthenticated') && user.isAuthenticated === false) && <Typography variant="caption" color="secondary">Your Number doesnt exist</Typography>}
                    <TextField
                        id="phone-number"
                        variant="standard"
                        color={(submitNumber && !user.isAuthenticated)? "secondary" : "primary" }
                        onChange={(event)=>settextInput(event.target.value)}
                    />
                    <Button onClick={()=>setsubmitNumber(textInput)} style={{textTransform:"none", marginTop:"10px"}}>Log In</Button>
                </div>
            </Fade>
            </Modal>
            {submitNumber && user.isAuthenticated && <Redirect to="/profile" />}                
        </>        
    );
}

export default Login;