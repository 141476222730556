import firebase from '../services/firebase'

const deletePhoto = async (userId, path) => {
    if (!firebase || !path || !userId) return;
    try {

        const storage = firebase().storage();
        const storageRef = storage.ref(`images/${userId}/${path}`);
        
        await storageRef.delete()
        return true;
    
    } catch (error) {
        console.log("error", error);
    }

}

export default deletePhoto;