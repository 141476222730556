import React from 'react';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

const useStyle = makeStyles((theme)=>({
    title_fonts: {
        ...theme.typography.title
    }
}))

function Story(props) {
    const {title, description} = props
    const classes = useStyle();
    const modileViewPort = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
            <Grid item container direction="row" alignItems="center">
                <Grid item xs={6} sm={2} alignItems="center" style={{order: (modileViewPort ? 2 : 1)}}>
                    <img src={process.env.PUBLIC_URL + "/img/giannis_solo.jpg"} alt="giannis_solo" width="100%"/>
                </Grid>
                <Grid id={title} xs={12} sm={8} item container direction="column" style={{order: (useMediaQuery ? 1 : 2)}}>
                    <Grid item container justify="center" style={{marginBottom:"40px"}}>
                        <Typography className={classes.title_fonts}>{title}</Typography>
                    </Grid>
                    <Grid item justify="flex-start" style={{backgroundColor: "#F1F1F1", padding:"2rem"}}>
                        <Typography style={{whiteSpace: "pre-line"}} variant="caption">{description}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={2} alignItems="center" style={{order: (modileViewPort ? 2 : 1)}}>
                    <img src={process.env.PUBLIC_URL + "/img/Fannie_solo.jpg"} alt="Fannie_solo" width="100%"/>
                </Grid>
            </Grid>          
    );
}

export default Story;