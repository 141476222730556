import firebase from '../services/firebase'

const getPhotos = async (userId) => {
    const photos = []
    
    if (!firebase || !userId) return photos;
    
    try {

        const storage = firebase().storage();
        const storageRef = storage.ref(`images/${userId}`);
        
        const firebase_photos = await storageRef.listAll();
        for (const photo of firebase_photos.items) {
            const photoURL = await photo.getDownloadURL()
            photos.push({
                img: photoURL,
                title: photo.name,
            })
        }

    } catch (error) {
        console.log("error", error);
    } finally {
        return photos
    }

}

export default getPhotos;