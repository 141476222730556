import {useState, useEffect} from 'react';

import {getUserByPhoneNumber, getUserById} from '../api/getUser'

function useProviderAuth() {
    const [user, setUser] = useState({})
    
    useEffect(() => {
        const getUser = async () => {
            if(localStorage && localStorage.getItem('user')) {
                let _user = await getUserById(localStorage.getItem('user'))
                _user.isAuthenticated = true;
                setUser(_user);
            } 
        }
        getUser();
    }, [])

    const loginByPhone = async (phoneNumber) => {
        const _user = await getUserByPhoneNumber(phoneNumber)
        if (_user.id) {
            if(localStorage) {
                localStorage.setItem('user', _user.id);
            }
            _user.isAuthenticated = true;
            setUser(_user);
        } else {
            _user.isAuthenticated = false;
            setUser(_user);
        }
        
    }

    const logout = () => {
        if(localStorage) {
            localStorage.clear();
        }
        setUser({})
    }

    return [
        user,
        loginByPhone,
        logout,
    ]
}

export default useProviderAuth;