import React from 'react';
import {ThemeProvider} from '@material-ui/core'
import {theme} from '../constraints/materialTheme'
import useProviderAuth from '../hooks/useProviderAuth';
import authContext from '../context/authContext'

function Provider({children}) {
    const auth = useProviderAuth()

    return (
        <authContext.Provider value={auth}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </authContext.Provider>
    );
}

export default Provider;