import firebase from '../services/firebase'
import { v4 as uuidv4 } from 'uuid';
import Compressor from 'compressorjs';

const uploadPhoto = async (uploadFile, userId, cb) => {
    if (!firebase || !uploadFile || !userId) return;
    try {

        const storage = firebase().storage();
        const storageRef = storage.ref(`images/${userId}`);
        
        new Compressor(uploadFile, {
            quality: 0.6,
            async success(result) {
                await storageRef.child(`${uuidv4()}_${result.name}`).put(result);
                alert("Successfully uploaded picture!");
                cb();
            },
            error(err) {
                throw err.message;
              },
        }) 
    
    } catch (error) {
        console.log("error", error);
    }

}

export default uploadPhoto;