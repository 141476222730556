import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import NavLink from '../Common/NavLink'
import Login from '../Common/Login'

const useStyle = makeStyles(()=>({
    sideBar: {
        position: 'fixed',
        top: '0px',
        width: '60%',
        zIndex: 99,
    },
    nav_no_diplay: {
        opacity: '0',
        width: '0%',
    },
    nav: {
        background:  "#F9F9F9",
        height: "100vh",
        zIndex: "1",
        transition: ".2s",
        display: "flex",
        flexDirection: "column",
    },
    navOptionArea: {
        flexGrow:1,
        display: 'flex',
        justifyContent: "center",
    },
    navOptionButtons: {
        marginTop: '10%',
        display: 'flex',
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "40%"
    },
    mobile_nav_action: {
        display: "flex",
    },
    diplay_none: {
        display: "none"
    },
    under_zIndex: {
        zIndex: -1
    },
    nav_img: {
        marginTop: '8px', width: "27%"
    }
}))


function SideNavBar(props) {
    const classes = useStyle();

    const [clickedMenu, setClickedMenu] = useState(false)
    const clickHandler = () => setClickedMenu(!clickedMenu)

    return (
        <div>
            <div className={(clickedMenu ? classes.sideBar : classes.diplay_none)}>
                <nav className={`${!clickedMenu ? classes.nav_no_diplay  : ''} ${classes.nav}`}>
                    <div className={classes.mobile_nav_action} style={{justifyContent: "flex-end"}}>
                        <IconButton onClick={clickHandler} className={(!clickedMenu && classes.diplay_none)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className={classes.navOptionArea}>
                        <div className={classes.navOptionButtons}>
                            <NavLink href="/#Ceremony" onClick={clickHandler}>Ceremony</NavLink>
                            <NavLink href="/#Our Story" onClick={clickHandler}>Our Story</NavLink>
                            <NavLink href="/#Party"    onClick={clickHandler}>Party</NavLink>
                            <Login />
                        </div>
                    </div>
                </nav>
            </div>
            <div className={classes.mobile_nav_action} style={{justifyContent: "flex-start"}}>
                        <IconButton onClick={clickHandler} className={(clickedMenu && classes.under_zIndex)}>
                            <MenuIcon />
                        </IconButton>
            </div>
        </div>
    );
}

export default SideNavBar;