import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import googleMapStyles from '../../constraints/googleMapStyle'

const {REACT_APP_API_KEY} = process.env

function Maps(props) {

    const mapStyles = {
        position: "relative",
        width: "100%",
        height: "300px",
      };
      const  myOptions = {
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: false,
        navigationControl: false,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
     };

    return (
        <Map
          google={props.google}
          zoom={15}
          containerStyle={mapStyles}
          styles={googleMapStyles.mapStyle}
          initialCenter={{ lat: (props.extra_data.lat || "36.1295314"), lng: (props.extra_data.lng || "28.0670951") }}
          {...myOptions}
        >
          <Marker position={{ lat: (props.extra_data.lat || "36.1295314"), lng: (props.extra_data.lng || "28.0670951") }} />
        </Map>
      );
}

export default GoogleApiWrapper({
apiKey: REACT_APP_API_KEY,
})(Maps);