import React from 'react';
import {Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(()=>({
    root: {
        "&:hover": {
            cursor: "pointer"
        }
    }
}))

function NavButton({isTopMenu, onClickHandler, children}) {
    const classes = useStyle();
    return (
        <Typography className={classes.root} variant={(isTopMenu ? "caption" : "body1")} onClick={onClickHandler}>{children}</Typography>           
    );
}

export default NavButton;