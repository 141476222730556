import React from 'react';
import {Grid} from '@material-ui/core'

import Layout from '../components/Common/Layout'

import Section from '../components/Landing/Section'
import Story from '../components/Landing/Story'

function Landing(props) {

    return (
        <Layout>
            <Grid item xs={12} sm={6} container justify="center" alignItems="center" style={{alignSelf:'center'}}>
                <img src={process.env.PUBLIC_URL + "/img/couple_with_cats.jpg"} alt="backgroundImage" width="100%"/>
            </Grid>
            <Grid item container style={{marginTop: '20px'}}>
                <Story 
                    title="Our Story"
                    description={`Πίσω στο 2008, ο ένας με την μαλλούρα του, η άλλη με τις αρβύλες της, κάπου ανάμεσα σε βιβλία εφαρμοσμένων μαθηματικών και φυσικής, μπήκε ο ένας στη ζωή του άλλου. 13 χρόνια, 2 μνημόνια, αρκετές καραντίνες και δύο γάτες μετά, η αγάπη μας άντεξε καλύτερα κι απ' τον Ρουβά και τώρα ήρθε η στιγμή που ίσως αναμένατε περισσότερο κι απ' το reunion των Φιλαρακίων! "The one where they finally get married." \n\n Το σπέσιαλ επεισόδιο της σειράς ζωντανά στις 22 Αυγούστου!`}
                />
            </Grid>
            <Grid item container style={{marginTop: '20px'}}>
                <Section 
                sectionTitle="Ceremony"
                sectionDesc="Αν θέλετε να ακούσετε αν θα ειπωθούν τα σωστά ονόματα στο γάμο, ελάτε να το μάθετε στο μοναστήρι του Αποστόλου Παύλου στη Λίνδο την Κυριακή 22 Αυγούστου στις 6:30μ.μ."
                img={{
                    src: (process.env.PUBLIC_URL + "/img/just married cats.jpg"),
                    alt: "just married cats",
                    width: "70%"
                }}
                map={{
                    lat: 36.0857681,
                    lng: 28.0885185,
                    redirectTo: "ZZhiF1vtpoy6vhDd7",
                }}
                />
            </Grid>
            <Grid item container>
                <Section 
                sectionTitle="Party - Update - Change Of Location"
                sectionDesc='Όσοι έχετε εξασκηθεί στις χορευτικές κινήσεις του "routine" σας προκαλούμε να τις επιδείξετε στην παρτάρα που θα ακολουθήσει στο Εστιατόριο Kyma στους Πεύκους. Όσοι πάλι δεν είστε τόσο χορευταράδες, μην πτοείστε, ελάτε για το φαϊ.'
                isRawReverse
                img={{
                    src: (process.env.PUBLIC_URL + "/img/dancin cats.jpg"),
                    alt: "dancin cats",
                    width: "100%"
                }}
                map={{
                    lat: 36.07136313744761,
                    lng:  28.052458464161834,
                    redirectTo: "AM8fDhX7WsdQTZUB9",
                }}
                />
            </Grid>
        </Layout>
    );
}

export default Landing;