import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";

import Landing from '../src/pages/Landing';
import Profile from '../src/pages/Profile';
import Provider from '../src/context/provider'
import ProtectedRoute from '../src/utils/ProtectedRoute'

function App(props) {
    return (
        <Provider>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Landing />
                    </Route>
                    <ProtectedRoute path="/profile">
                        <Profile />
                    </ProtectedRoute>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;