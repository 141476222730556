import React, {useState, useContext} from 'react';

import { CircularProgress, IconButton, Modal, makeStyles, Backdrop, Fade, Typography, Button} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import deletePhotoApi from '../../api/deletePhotos'

import authContext from '../../context/authContext'


const useStyle = makeStyles(()=>({
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: 'white',
        borderWidth: "0px",
        padding: "40px 50px"
    },
    logIn: {
        display: "flex",
    },
    modalButtons: {
        display: 'flex',
        justifyContent: "center"
    }
}))


function Image({src, title, hasDeleteOption, actions}) {
    const classes = useStyle();

    const {setreloadPhotos} = actions 
    
    const [onLoaded, setonLoaded] = useState(false)
    const [open, setOpen] = useState(false)
    
    const [user] = useContext(authContext);


    const deletePhoto = async (title) => {
        if(user.id && title) {
            await deletePhotoApi(user.id, title);
            setreloadPhotos(current => !current)
            setOpen(false)
        }
    }



    return (
        <div style={{position: 'relative'}}>
            {onLoaded && hasDeleteOption && 
            <IconButton onClick={()=>setOpen(true)} style={{position: 'absolute'}}>
                <CloseIcon fontSize="small" style={{color: "#b3b3b3"}}/>
            </IconButton>}
            <img
            srcSet={src}
            alt={title}
            width="100%"
            onLoad={()=> setonLoaded(true)}
            />
            {!onLoaded && <CircularProgress />}
            <Modal
                className={classes.modal}
                open={open}
                onClose={()=>setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography style={{marginBottom: "20px"}}>Are you Sure?</Typography>
                        <div className={classes.modalButtons}>
                            <Button onClick={()=>deletePhoto(title)} style={{textTransform:"none", marginTop:"10px"}}>Yes</Button>
                            <Button onClick={()=>setOpen(false)} style={{textTransform:"none", marginTop:"10px"}}>No</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default Image;