import React from 'react';
import {Grid, useMediaQuery} from '@material-ui/core'

import TopNavBar from './TopNavBar'
import SideNavBar from './SideNavBar'
import Footer from './Footer'

function Layout(props) {
    const {children} = props;
    const modileViewPort = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Grid container direction="column" justify="center">
            {modileViewPort ? <SideNavBar /> : (<Grid item><TopNavBar /></Grid>)}
            {children}
            <Grid item container style={{marginTop: '20px'}}>
                <Footer />
            </Grid>
        </Grid>
    );
}

export default Layout;